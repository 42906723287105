@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(34, 34, 34);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Sticky class for headers  */

.headerIsSticky {
  background-color: #fff;
}

.headerIsSticky :is(h1, h2, h3, h4, h5, h6, p) {
  color: #222;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}

@media (prefers-color-scheme: dark) {
  .headerIsSticky {
    background-color: #000;
  }

  .headerIsSticky :is(h1, h2, h3, h4, h5, h6, p) {
    color: #fff;
  }
}

/* PWA CODE  */
/* 
@media only screen and (max-width: 991px) and (hover: none) {
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-overflow-scrolling: auto;
  }

  *::-webkit-scrollbar {
    width: 0px;
  }
} */
